<template>
  <div class="mainbox">
    <div class="left">
      <div class="modulebox f_column">
        <div class="title"><span>公司简介</span></div>
        <div class="content aboutus">
          <div class="left"><img :src="companySrc"></div>
          <div class="right">
            <h1>{{companyName}}</h1>
            <div class="text">{{companyIntro}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>分支公司</span></div>
        <div class="content branchcompany">
          <div class="list" v-for="(item,index) in branchcompany" :key="index">
            {{item?item.companyName:''}}
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>团队介绍</span></div>
        <div class="content team">
          <div class="top">
            <li v-for="(item,index) in teamlist" :key="index">
              <b>{{item.value}}</b><span>{{item.text}}</span>
            </li>
          </div>
          <div class="bottom">
            <p style="text-align:center;" v-if="userlist.length==0">暂无数据</p>
            <div v-else class="listbox">
              <vue-seamless-scroll :data="userlist" :class-option="{limitMoveNum: 3,direction: 2,step: 0.4,}">
                <div class="list" v-for="(item,index) in userlist" :key="index">
                  <div class="left">
                    <img :src="item.pictures"><p>{{item.name}}</p>
                  </div>
                  <div class="right">{{item.remark}}</div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="modulebox f_column" style="overflow:hidden">
        <div class="title"><span>组织架构</span></div>
        <p style="text-align:center;" v-if="testData.length==0">暂无数据</p>
        <div v-else class="content okr-content-tree">
          <vue-okr-tree 
            ref="tree" 
            node-key="id"
            :render-content="renderContent"
            :data="testData">
          </vue-okr-tree> 
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>历年产值</span></div>
        <div class="content">
          <div ref="bar1" class="echarts"></div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>大事件纪要</span></div>
        <p style="text-align:center;" v-if="summarylist.length==0">暂无数据</p>
        <div v-else class="content summary">
          <el-carousel :interval="4000" type="card" height="100%">
            <el-carousel-item v-for="(item,index) in summarylist" :key="index">
              <div class="img">
                <img style="background:#fff" :src="item.src"></div>
              <h3 :title="item.title" style="display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;">{{item.title}}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts');
import api from '@/apis/index2'
import {VueOkrTree} from 'vue-okr-tree';
import 'vue-okr-tree/dist/vue-okr-tree.css'
import vueSeamlessScroll from 'vue-seamless-scroll'
import common from '@/components/monitorCommon/common'
export default {
  components:{
    VueOkrTree,
    vueSeamlessScroll
  },
  data() {
    return {
      testData:'',//树数据
      companySrc:require('@/assets/images/pic1.png'),//公司图片
      companyName:'--',//公司名称
      companyIntro:'--',//公司简介
      branchcompany:[//分支公司
        {id:0,companyName:'--'},
        {id:1,companyName:'--'},
        {id:2,companyName:'--'},
        {id:3,companyName:'--'},
        {id:4,companyName:'--'},
        {id:5,companyName:'--'},
        {id:6,companyName:'--'},
        {id:7,companyName:'--'},
      ],
      teamlist:[
        {id:0,text:'员工总数',value:'0'},
        {id:1,text:'管理人才',value:'0'},
        {id:2,text:'技术人才',value:'0'},
        {id:3,text:'学术人才',value:'0'},
        {id:4,text:'工程人才',value:'0'},
        {id:5,text:'技能人才',value:'0'},
      ],
      userlist:'',
      summarylist:''
    };
  },

  methods:{
    //获取历年产值
    getYearMoney(){
      api.getProductValue().then(res=>{
        if(res.code==10000){
          let _year = []
          let _num = []
          if(res.data&&res.data.list.length!=0){
            let _data = res.data.list
            for(var i in _data){
              _year.push(_data[i].proDate.substring(0,4))
              _num.push(_data[i].proValue)
            }
            this.bar1(_year,_num)
          }else{
            this.bar1([],[])
          }
        }
      })
    },
    //自定义树节点
    renderContent (h, node) {
      return (
        <div>
          <div class={['diy-wrapper', node.isCurrent ? 'current-select' : ''] }>
            <div class="diy-left-box">
              <img class="diy-con-img" src={node.data.stationImg}/>
            </div>
            <div class="diy-right-box">
              <p>{node.data.stationUser}</p>
              <p>{node.data.stationName}</p>
              <p>{node.data.stationDuty}</p>
            </div>
          </div>
        </div>
      )
    },
    //获取组织架构
    getOrganization(){
      api.getListTree().then(res=>{
        if(res.code==10000){
          if(res.data.length<=1){
            this.testData = res.data
          }
        }
      })
    },
    //获取重要人才
    getImportantUesr(){
      api.getStaffUsers().then(res=>{
        if(res.code==10000){
          if(res.data){
            this.userlist = res.data
          }
        }else{
          this.$message.error('获取重要人才失败');
          return;
        }
      })
    },
    //获取团队介绍
    getTeam(){
      api.getTeamView().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.teamlist[0].value = res.data.personTotal
            this.teamlist[1].value = res.data.manager
            this.teamlist[2].value = res.data.technician
            this.teamlist[3].value = res.data.academic
            this.teamlist[4].value = res.data.engineering
            this.teamlist[5].value = res.data.skilled
          }
        }else{
          this.$message.error('获取人才信息失败');
          return;
        }
      })
    },
    //获取分支公司
    getLittleCompany(){
      api.getChildCompany().then(res=>{
        if(res.code==10000){
          if(res.data&&res.data.length!=0){
            let _data = res.data
            let _arr = []
            for(var i = 0;i<=7;i++){
              _arr.push(_data[i])
            }
            this.branchcompany = _arr
          }
        }else{
          this.$message.error('获取分支公司失败');
          return;
        }
      })
    },
    //获取大事件纪要
    getSummary(){
      api.getBigCulture().then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data.list;
            let _arr = _data.map((item,index)=>{
              return{
                id:index,
                title:item.content,
                src:item.pictures?item.pictures:require('@/assets/images/isNoPic.png')
              }
            })
            this.summarylist = _arr
          }
        }else{
          this.$message.error('获取纪要失败');
          return;
        }
      })
    },
    //获取公司简介
    getCompanyMsg(){
      api.getCompanyInfo().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.companySrc = res.data.pictures?res.data.pictures:require('@/assets/images/pic1.png')
            this.companyName = res.data.companyName
            this.companyIntro = res.data.remark?res.data.remark:'暂无简介'
          }
        }else{
          this.$message.error('获取公司简介失败');
          return;
        }
      })
    },




    bar1(a,b){
      let bar1Chart = echarts.init(this.$refs.bar1);
      common.moveEcharts(a.length,bar1Chart)
			bar1Chart.setOption({
        color:['#ffff00'],
        grid: {
            left: '6%',right: '4%', bottom: '18%', top:'12%'
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          },
          formatter:'{c}万元'
        },
        title: {
          // subtext: '单位:万元',left: '4%',
          // subtextStyle:{ fontSize: 20, color:'#ccd0f2'},
          show: a.length == 0,
          textStyle: {
            color: "#fff",
            fontSize: 20,
          },
          text: "暂无数据",
          left: "center",
          top: "center",
        },
        xAxis: {
            type: 'category', 
            data: a.reverse(),
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            type: 'bar',barWidth: '20%',
            label:{
              show:true, position:'top',textStyle:{ color:'#fff',fontSize: 20}
            },
            data: b.reverse()
          }
        ]
			})
    },
  },
  mounted() {
    this.getCompanyMsg();//获取公司简介
    this.getSummary();//获取大事件纪要
    this.getLittleCompany();//获取分支公司
    this.getTeam();//获取团队介绍
    this.getImportantUesr();//获取重要人才
    this.getOrganization();//获取组织架构
    this.getYearMoney();//获取历年产值  
  },
  beforeDestroy(){
    //清除所有定时器
    let _end = setInterval(function(){},2000);
    for(let i =1;i<=_end;i++){
      clearInterval(i)
    }
  },
}
</script>

<style lang="less" scoped >
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;
  &>.left{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: left;
    .modulebox:nth-child(1){ margin-top: 0; flex: 2; flex-shrink: 0;}
    .modulebox:nth-child(2){ flex: 1; flex-shrink: 0;}
    .modulebox:nth-child(3){ flex: 1.5; flex-shrink: 0; overflow: hidden;}
    .f_row{ height: 50%; flex-shrink: 0;
      .f_column{ width: 50%; flex-shrink: 0;}
    }
  }
  &>.right{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: right;
    .modulebox{ flex: 1; flex-shrink: 0;}
    .modulebox:nth-child(1){ margin-top: 0; flex:1.5;}
    .f_row{ height: 50%; flex-shrink: 0;
      .f_column{ width: 50%; flex-shrink: 0;}
    }
  }
  .aboutus{ display: flex; flex-direction: column; overflow: hidden; padding:.4rem 1rem 1.5rem;
    .left{ height: 18rem; overflow: hidden; margin-bottom: 1rem;
      img{ width: 100%; height: 100%;}
    }
    .right{ flex: 1; height: 100%; overflow: hidden;
      h1{ font-size: 1.3rem; color: #fff; text-align: center; margin: 5px 0 15px; font-weight: 600;}
      .text{ text-indent: 2rem; padding:.5rem; line-height:1.6rem;}
    }
  }
  .branchcompany{float: left; display: flex; flex-direction: row; flex-wrap: wrap; padding:.4rem 0 1rem; align-items: center; justify-content: space-evenly;
    .list{ width: 23rem; padding-right: 15px; color: #fff; height:2.45rem; display: flex; align-items: center; justify-content: center; background: url(../assets/images/cbg.png) center no-repeat;}
  }
  .team{ display: flex; flex-direction: column; padding:.4rem 1.2rem 1rem; overflow: hidden;
    .top{ height: 6.5rem; flex-shrink: 0; display: flex; background: #032685; border: #005be3 solid 1px; flex-direction: row; align-items: center; 
      li{ flex: 1; display: flex; border-right: #005be3 solid 1px; flex-direction: column; align-items: center; justify-content: center;
        b{ font-weight: 500; font-size: 1.6rem; margin-bottom: 10px; color: #00ffcc;}
      }
      li:last-child{ border-right: none;}
    }
    .bottom{ flex: 1; height: 100%; margin-top: 1.2rem;
      .listbox{ width: 100%; height: 100%; width:max-content;
        .list{ float: left;display: inline-flex; width: 17rem; vertical-align:top; margin-right: 2rem; height: 100%; overflow:hidden;
          .left{ width: 5rem; display: flex; flex-direction: column;
            img{ width: 5.5rem; height:7rem; display: block; margin-bottom: .5rem;}
            p{ background: #005be3; text-align: center; line-height: 1.7rem; color: #fff; font-size: 1rem;}
          }
          .right{ margin-left: 1.3rem; height: 100%; overflow: hidden; font-size: .9rem; line-height: 1.4rem;}
        }
      }
    }
  }
  .summary{ position: relative; padding-top: .4rem;
    .el-carousel{ height: 100%; width: 100%; z-index: 6;}
    h3{ font-weight: 500; color: #00ffcc; margin-top: 20px; display: none !important;}
    .img{ width: 90%; border: #00ffcc solid 4px; margin: 0 auto; box-shadow: 0 0 35px #000;
      img{ width: 100%; display: block; height: 15.5rem; border: none;} 
    }
  }
  .summary::after{ content: ' '; position: absolute; width: 100%; height: 4rem; background: url(../assets/images/picbg.png) top center no-repeat; z-index: 0; bottom: .7rem;}
  .summary{
    .el-carousel__indicators{ display: none;}
    .el-carousel__item{ text-align: center;}
    .el-carousel__item--card.is-active{ 
      h3{ display: block !important;}
    }
    
  }
  /deep/ .okr-content-tree{ height: 100%; overflow: auto;
    overflow: auto;
    text-align: center;
    .org-chart-node-children{ width:max-content }
    .diy-wrapper{ display: flex; flex-direction: row; padding:10px; height: auto; overflow: hidden;
      .diy-con-img{ width: 3rem; height: 3.5rem; display: block;}
      .diy-con-button{margin:0.2rem 0;}
      .diy-right-box{ margin-left: 15px; font-size: .65rem; width: 4rem; overflow: hidden; text-align: left; display: flex; flex-direction: column;
        p:nth-child(1){ color: #aaa; font-weight: bold; margin-bottom: 4px;}
        p:nth-child(2){ opacity: .6; font-size: .55rem; margin-bottom: 3px;}
        p:nth-child(3){ height: 100%; flex: 1; overflow: hidden; font-size: .55rem;}
      }
    }
    .vertical .org-chart-node-label .org-chart-node-label-inner{ padding:0; margin:0px 10px;}
    .vertical .org-chart-node-children:before,.vertical .org-chart-node:after, .vertical .org-chart-node:before{ border-color: #aaa;}
  }
}
</style>
