import axios from 'axios'

export default {
  //查询运维统计
  getCompanyInfo(){
    return axios.get('/show/index2/getCompanyInfo');
  },
  //查询大事件纪要
  getBigCulture(){
    return axios.get('/show/index2/culture?limit=6');
  },
  //查询分支公司
  getChildCompany(){
    return axios.get('/show/index2/childCompany');
  },
  //查询团队介绍
  getTeamView(){
    return axios.get('/show/index2/teamView');
  },
  //查询重要人才
  getStaffUsers(){
    return axios.get('/show/index2/staff');
  },
  //查询组织架构
  getListTree(){
    return axios.get('/show/index2/listTree');
  },
  //查询历年产值
  getProductValue(){
    return axios.get('/show/index2/productValue');
  },
}
